
import { defineComponent, reactive, ref, onMounted, UnwrapRef } from "vue";
import { notification } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import superadminService from "../services/superadmin";
import {
} from "@ant-design/icons-vue";

interface Iprice {
  credits_till: string;
  price_per_unit: string;
}

export default defineComponent({
    emits: ["ClosePriceModal"],
    props: ['PriceID'],
    components: {
    },
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const priceFormRef = ref();
    const saveText = ref();
    const priceRules = validate.priceValidation;
    const priceModal: UnwrapRef<Iprice> = reactive({
      credits_till: '',
      price_per_unit: '',
    });
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const sendNewprice = async (value: any) => {
      confirmLoading.value = true;      
      try {
        let res;
        if(props.PriceID) {
          res = await superadminService.editPrice(value, props.PriceID);
        } else {
          res = await superadminService.addPrice(value);
        }
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          priceFormRef.value.resetFields();
          emit("ClosePriceModal", 'SuccessModal');
        }
        confirmLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          await notify("Error", error.response.data.message, "error");
        }
        confirmLoading.value = false;
      }
    };
    const sendRequest = async () => {
      priceFormRef.value
        .validate()
        .then(() => {
          sendNewprice(priceModal);
        })
        .catch((error: ValidateErrorEntity<Iprice>) => {
          console.log("error", error);
        });
    };
    const getPrice = async (id: any) => {
      try {
        const res = await superadminService.getPrice(id);
        if (res.data.data.success) {
          // getPriceByID.value = res.data.data.price;
          priceModal.credits_till = res.data.data.price.credits_till.toString();
          priceModal.price_per_unit = res.data.data.price.price_per_unit;          
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    const cancelPricerequest = async () => {
        emit("ClosePriceModal", 'CloseModal');
    }
    onMounted(async () => {
      if(props.PriceID) {
          saveText.value = "Update";
          getPrice(props.PriceID);
        } else {
          saveText.value = "Save";
          priceModal.credits_till = "";
          priceModal.price_per_unit = "";
        }
    });
    return {
      priceFormRef,
      priceModal,
      priceRules,
      confirmLoading,
      sendRequest,
      notify,
      saveText,
      // getPrice,
      cancelPricerequest,
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
  },
});
