
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { notification, Modal } from "ant-design-vue";
import superadminService from "../../services/superadmin";
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import _ from "lodash";

import addNewPriceModal from "@/components/addNewPrice.vue";

export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined,
    addNewPriceModal,
  },
  setup() {
    const priceId = ref();
    const creatPriceVisible = ref<boolean>(false);
    const pageLoading = ref<boolean>(false);
    const priceLists = ref([]);
    const priceColumns = [
      {
        title: "Credits Till",
        dataIndex: "credits_till",
      },
      {
        title: "Price Per Unit",
        dataIndex: "price_per_unit",
      },
      {
        title: "Created By",
        dataIndex: "created_person",
      },
      {
        title: "Action",
        align: "center",
        slots: { customRender: "action" },
      },
    ];
    const getAllPrice = async () => {
      pageLoading.value = true;
      try {
        const res = await superadminService.getAllPrices();
        if (res.data.data.success) {
          priceLists.value = res.data.data.prices;
        }
        pageLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoading.value = false;
        }
      }
    };
    const showDeleteConfirm = async (priceId: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this Price? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deletePrice(priceId);
        },
      });
    };
    const deletePrice = async (priceId: any) => {
      try {
        const res = await superadminService.deletePrice(priceId);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          getAllPrice();
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const addNewPrice = async () => {
      priceId.value = undefined;
      addEditPriceTitle.value = "Add Price";
      creatPriceVisible.value = true;
    };
    const editPrice = async (data?: any) => {
      priceId.value = data;
      addEditPriceTitle.value = "Edit Price";
      creatPriceVisible.value = true;
    };
    const closePriceModal = async (data: any) => {
      if (data === "SuccessModal") {
        getAllPrice();
        creatPriceVisible.value = false;
      } else {
        creatPriceVisible.value = false;
      }
    };
    const addEditPriceTitle = ref();
    onMounted(async () => {
      getAllPrice();
    });
    return {
      addNewPriceModal,
      priceColumns,
      priceLists,
      showDeleteConfirm,
      creatPriceVisible,
      addNewPrice,
      closePriceModal,
      pageLoading,
      editPrice,
      addEditPriceTitle,
      priceId
    };
  },
});
