import axios from 'axios'

export default {
    getAllAgency(data: any) {
        return axios.post('agencies', data)
    },
    getAllPrices(){
        return axios.get('prices')
    },
    viewAgencyById(id: any) {
        return axios.get('agency/' + id )
    },
    changeAgencyStatus (id: any, data: any)  {
        return axios.put('agency/' + id + '/status', data)
    },
    deletePrice (id: any)  {
        return axios.put('price/' + id + '/delete')
    },
    addPrice(data: any) {
        return axios.post('price/add', data)
    },
    editPrice(data:any, id: any) {
        return axios.put('price/' + id + '/edit', data)
    },
    getPrice (id: any)  {
        return axios.get('price/' + id)
    },
    addCredit (id: any, data: any)  {
        return axios.post('agency/' + id + '/add-credit', data)
    },
    getAllOrganisations(){
        return axios.get('oauth/clients')
    },    
    getOrganisation (id: any)  {
        return axios.get('oauth/clients/' + id)
    },
    addOrganisation(data: any) {
        return axios.post('oauth/clients/', data)
    },
    editOrganisation(data:any, id: any) {
        return axios.put('oauth/clients/' + id, data)
    },
    deleteOrganisation(id: any){
        return axios.delete('oauth/clients/' + id)
    },
    authorize(data: any) {
        return axios.post('oauth/authorize/', data)
    },
    cancelAuthRequest(data: any){
        return axios.delete('oauth/authorize/', data)
    },
    setAuthorization(data: any){
        return axios.get('authorize' + data)
    }
}